import React, { useState, useEffect, useContext } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { useParams } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import { decryptFromStore, encryptAndStore } from "../../helper/sessionStore"; // Replace './encryptionUtils' with the correct path to your file
import {
    BreadcrumbSkeleton,
    ButtonSkeleton,
    ContentSkeleton,
    SidebarSkeleton,
    TitleBadgeSkeleton,
} from "../../components/SkeletonLayouts";
import { Is_Login } from "../../helper/IsLogin";
import PointsHeader from "../../components/NewBusiness/PointsHeader";

function BusinessCourse() {
    const { loder, setLoder, user, getTrailsTotalPoint } = useContext(CourseContext);
    const location = useLocation()
    const navigate = useNavigate()
    const [listCourse, setListCourse] = useState({ results: [], page_size: 0 });
    const [chapterNo, setChapterNo] = useState(0);
    const [chapterDetails, setChapterDetails] = useState({});
    const [totalChapters, setTotalChapters] = useState(0);
    const [showReference, setShowReference] = useState(false);
    const serverURL = getServerURL();
    const { id } = useParams(); // This retrieves the ID parameter from the URL
    const isLoggedIn = Is_Login();
    const [showSubmitButton, setShowSubmitButton] = useState(false)

    const getCourses = async () => {
        try {
            setLoder(true);
            const type = ( user.main_role === 1 || user.role === 2) ? "business" : "kid"
            const response = await api.getWithToken(
                `${serverURL}trails/course/?type=${type}`,
                { course_id: id }
            );
            setListCourse(response.data);
            setChapterDetails(response.data.chapters[0]);
            setTotalChapters(response.data.chapters.length);
            setLoder(false);
            getTrailsTotalPoint();
            window.scrollTo(0, 0);
        } catch (error) {
            console.log(error);
            setLoder(false);
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        getCourses()
    }, [isLoggedIn]);

    const setQuiz = () => {
        sessionStorage.removeItem("endTime");
        sessionStorage.removeItem("remainingTime");
        sessionStorage.removeItem("quiz");
        sessionStorage.removeItem("QuestionNo");
        sessionStorage.removeItem("answers");
        sessionStorage.removeItem("answers_index");
        sessionStorage.removeItem("answers_count");
        sessionStorage.removeItem("FinalAnswers");
        sessionStorage.removeItem("chatGptResult");
        sessionStorage.removeItem("isSubmit");
        sessionStorage.removeItem("isTrue");
        sessionStorage.removeItem("true_id");
        sessionStorage.removeItem("remainingPathColor");
        sessionStorage.removeItem("feedback");
        sessionStorage.clear()
        localStorage.removeItem("remainingPathColor")
        localStorage.removeItem("remainingTime")

        // Encrypt and store sensitive information
        encryptAndStore("businessCourse", {
            courseDetails: listCourse.course_details,
            quizQuestions: listCourse.quiz_question
        });
    };

    useEffect(() => {
        if (listCourse?.chapters?.length > 0) {
            if (chapterNo <= totalChapters - 1) {
                if(chapterNo === totalChapters - 1) {
                    if (listCourse?.references?.length === 0 && !listCourse?.notes) {
                        setShowSubmitButton(true);
                    }
                }
                setChapterDetails(listCourse.chapters[chapterNo]);
            } else {
                setShowReference(true);
            }
            window.scrollTo(0, 0);
        }
    }, [chapterNo]);

     useEffect(() => {
            if (!location?.state?.step) {
                navigate("/data-privacy-trail");
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    return (
        <>
            <Header />

            {loder ? (
                <div className="container">
                    <BreadcrumbSkeleton />
                    <TitleBadgeSkeleton />
                    <div className="row">
                        <div className="col-lg-8">
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ContentSkeleton />
                            <ButtonSkeleton />
                        </div>
                        <div className="col-lg-4">
                            <SidebarSkeleton />
                            <SidebarSkeleton />
                        </div>
                    </div>
                </div>
            ) : (
                <>
                <main>
                    <section>
                        <PointsHeader to="/data-privacy/points" />
                    </section>
                    <section>
                            <div className="featured-main">
                                <div className="container">
                                    <div className="row g-4 g-md-3 g-lg-4">
                                        <div className="col-md-12 col-lg-8">
                                            <h2 className="text-black fw-400 mb-4 mb-md-5 mb-lg-6">
                                                {listCourse?.course_details?.name}
                                            </h2>
                                            <div>
                                                {chapterDetails && !showReference && (
                                                    <>
                                                        <h4 className="text-black fw-500">
                                                            {chapterDetails.name}
                                                        </h4>
                                                        {chapterDetails?.chapter_description &&
                                                            chapterDetails?.chapter_description.map(
                                                                (
                                                                    description,
                                                                    descIndex
                                                                ) => (
                                                                    <div
                                                                        key={descIndex}
                                                                    >
                                                                        {description?.title && (
                                                                            <p
                                                                                key={descIndex}
                                                                            >
                                                                                { chapterDetails?.chapter_description.length > 1 && descIndex +
                                                                                    1 + "."}
                                                                                {" "}
                                                                                {description?.title}
                                                                            </p>
                                                                        )}
                                                                        <p className="text-muted fw-400 mt-3 mb-5 fs-6">
                                                                            {!description?.title
                                                                                ? `${descIndex +
                                                                                1}. ${description.description}`
                                                                                : description.description}
                                                                        </p>
                                                                    </div>
                                                                )
                                                            )}
                                                    </>
                                                )}

                                                {showReference && (
                                                    <>
                                                        <b className="text-muted pt-5 d-block fs-6">
                                                            References:
                                                        </b>
                                                        <ul className="references-list mt-4 ps-3">
                                                            {listCourse?.references &&
                                                                listCourse?.references.map(
                                                                    (data, index) => (
                                                                        <span key={index}>
                                                                            <li
                                                                                key={index}
                                                                            >
                                                                                <q className="text-muted">
                                                                                    {data.description}
                                                                                </q>
                                                                            </li>
                                                                        </span>
                                                                    )
                                                                )}
                                                        </ul>

                                                        {listCourse?.notes && (
                                                            <>
                                                                <p className="text-muted mt-5 fs-7">
                                                                    {listCourse?.notes}
                                                                </p>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div className="row justify-content-between align-items-center mb-2 my-md-4 my-lg-5 g-2">
                                                {(showReference || showSubmitButton) ? (
                                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                                        <Link
                                                            to={`/data-privacy/course-quiz`}
                                                            type="button"
                                                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                            onClick={() => setQuiz()}
                                                        >
                                                            Start quiz
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M21 6.99805L16 12.9995"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round" />
                                                            </svg>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                                        <button
                                                            type="button"
                                                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 w-100 mt-4"
                                                            onClick={() => setChapterNo(
                                                                chapterNo + 1
                                                            )}
                                                        >
                                                            Next
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={22}
                                                                height={14}
                                                                viewBox="0 0 22 14"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M1 6.99854H21L16 1"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round" />
                                                                <path
                                                                    d="M21 6.99805L16 12.9995"
                                                                    stroke="white"
                                                                    strokeWidth="1.5"
                                                                    strokeLinecap="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </section>
                </main>
                </>
            )}
            <Footer />
        </>
    );
}

export default BusinessCourse;
