import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ProgressBar } from "react-bootstrap";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";
import {
    decryptFromStoreLoc,
    decryptToken,
    encryptAndStoreLoc,
} from "../../helper/localStore";
import { CourseContext } from "../../context/CourseContext";
import ReportAnIssue from "../../components/NewBusiness/ReportAnIssue";

const BusinessCaseStudyQuestion = () => {
    const serverURL = getServerURL();
    const location = useLocation();
    const navigate = useNavigate();
    const { getTrailsTotalPoint } = useContext(CourseContext);
    const [caseStudyList, setCaseStudyList] = useState([]);
    const [caseStudyData, setCaseStudyData] = useState({});
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [buttonName, setButtonName] = useState("Submit");
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(0);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const [activeButton, setActiveButton] = useState(null);
    const letters = ["A.", "B.", "C.", "D."];

    const handleClose = () => setShow(false);

    // Handle radio button change
    const handleRadioChange = (e) => {
        setSelectedRadio(e.target.id);
    };

    // Handle button click
    const handleButtonClick = (buttonText) => {
        setActiveButton(buttonText);
    };

    useEffect(() => {
        if (location?.state?.caseStudy) {
            setCaseStudyData(location.state.caseStudy);
            setCaseStudyList(location.state.caseStudyList);
            const answer = decryptToken(
                location.state.caseStudy.questions[0].id
            );
            setCorrectAnswer(answer);
            const index = location.state.caseStudyList.findIndex(
                (caseStudy) =>
                    caseStudy.case_study_id ===
                    location.state.caseStudy.case_study_id
            );
            setProgress(index + 1);
        } else {
            navigate("/data-privacy/data-privacy-step");
        }
    }, []);

    const submitScore = async () => {
        try {
            const data = decryptFromStoreLoc("caseStudy");
            if (data) {
                let points = 0;
                let total = 0;
                let correct = 0;
                data.forEach((item) => {
                    if (item.isTrue) {
                        correct++;
                    }
                    points += item.point;
                    total++;
                });
                const res = await api.postWithToken(
                    `${serverURL}trails/step-progress/`,
                    {
                        trail_step: 1,
                        status: "completed",
                        points_earned: points,
                        score: Math.round((correct / total) * 100),
                    }
                );
                getTrailsTotalPoint()
                encryptAndStoreLoc("caseStudyResult", {
                    score: Math.round((correct / total) * 100),
                    points_earned: points,
                    correct: correct,
                    total: total,
                })
            }
            navigate("/data-privacy/case-studies-results");
        } catch (error) {
            console.log(error);
        }
    };

    const handleContinue = async () => {
        if (!selectedRadio) {
            toast.error("Please select answer!");
            return;
        }

        if (buttonName === "Submit") {
            setSelectedAnswer(selectedRadio);
            if (selectedRadio == correctAnswer) {
                setShow(true);
                const res = await api.postWithToken(
                    `${serverURL}trails/point-history/`,
                    {
                        activity_type: "case-study-completed",
                        activity: "Case Study Completed",
                        point_gained: 3,
                    }
                );
                getTrailsTotalPoint()

                setTimeout(() => {
                    setShow(false)
                }, 1500);
            }
            setButtonName("Next");

            const localDataArray = decryptFromStoreLoc("caseStudy");
            const isAnswerCorrect = selectedRadio === correctAnswer;
            const points = isAnswerCorrect ? 3 : 0;
            if (localDataArray) {
                // Find the case study object in the local data array
                const existingCaseStudy = localDataArray.find(
                    (item) => item.case_study_id === caseStudyData.case_study_id
                );

                if (existingCaseStudy) {
                    // Update the existing object if found
                    existingCaseStudy.isTrue = isAnswerCorrect;
                    existingCaseStudy.point = points;
                } else {
                    // Create a new object if not found
                    localDataArray.push({
                        case_study_id: caseStudyData.case_study_id,
                        isTrue: isAnswerCorrect,
                        point: points,
                    });
                }

                // Save the updated array back to local storage
                encryptAndStoreLoc("caseStudy", localDataArray);
            } else {
                // If no existing data, create a new array with the current case study data
                const newCaseStudyData = {
                    case_study_id: caseStudyData.case_study_id,
                    isTrue: isAnswerCorrect,
                    point: points,
                };
                encryptAndStoreLoc("caseStudy", [newCaseStudyData]);
            }
        }

        if (buttonName === "Next") {
            const index = caseStudyList.findIndex(
                (obj) => obj.case_study_id === caseStudyData.case_study_id
            );
            if (caseStudyList.length !== index + 1) {
                navigate("/data-privacy/case-study", {
                    state: {
                        case_study_id: caseStudyList[index + 1].case_study_id,
                    },
                });
            } else {
                submitScore();
            }
        }
    };

    const getCheckBoxValue = (selectedRadio, answer, correctAnswer) => {
        let val = false;
        if(selectedRadio == answer?.answer_id) {
            val = true;
        }
        if(buttonName === "Next" && selectedRadio && correctAnswer == answer?.answer_id) {
            val = true
        }
        return val;
    }


    return (
        <>
            <Header />
            <main className="business_public_spaces business_public_question">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                </section>
                <section className="business_case_study">
                    <div className="container-fluid">
                        <div className="row w-100 w-sm-100">
                            <div className="col-12 col-md-6 left_side_images">
                                {!selectedAnswer && (
                                    <img
                                        src="/new-business/questions-cuate.webp"
                                        className="m-auto d-flex"
                                        width={400}
                                        alt=""
                                    />
                                )}
                                {selectedAnswer &&
                                    correctAnswer !== selectedAnswer && (
                                        <img
                                            src="/new-business/incorrect.webp"
                                            className="m-auto d-flex"
                                            width={400}
                                            alt=""
                                        />
                                    )}
                                {correctAnswer === selectedAnswer && (
                                    <img
                                        src="/new-business/correct.webp"
                                        className="m-auto d-flex"
                                        width={400}
                                        alt=""
                                    />
                                )}
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                                <div>
                                    <div className="right_side_information">
                                        <div className="card border-0 rounded-3 mb-4">
                                            <div className="card-body">
                                                <h5 className="text-center">
                                                    Question
                                                </h5>
                                                <div className="d-flex justify-content-center align-items-center gap-2">
                                                    <p className="pb-0 mb-0 text-center">
                                                        {caseStudyData &&
                                                        caseStudyData?.questions && caseStudyData?.questions[0]?.question}
                                                    </p>
                                                    <ReportAnIssue
                                                        trailStep={1}
                                                        questionId={
                                                            (caseStudyData &&
                                                        caseStudyData?.questions && caseStudyData?.questions[0]?.question) ? caseStudyData?.questions[0]?.question_id : null
                                                        }
                                                    />
                                                </div>
                                                <div className="question_list pt-5 px-xl-4">
                                                    {caseStudyData &&
                                                        caseStudyData?.questions &&
                                                        caseStudyData?.questions[0]?.case_study_answers?.map(
                                                            (answer, index) => (
                                                                <label
                                                                    className={`form-check-label d-inline-flex justify-content-between align-items-center w-100 gap-4 ${
                                                                        selectedRadio ==
                                                                        answer.answer_id
                                                                            ? "selected"
                                                                            : ""
                                                                    }  ${
                                                                        selectedAnswer &&
                                                                        correctAnswer ==
                                                                            answer.answer_id &&
                                                                        "right"
                                                                    }  ${
                                                                        selectedAnswer &&
                                                                        selectedAnswer ==
                                                                            answer.answer_id &&
                                                                        "wrong"
                                                                    }`}
                                                                    htmlFor={
                                                                        answer.answer_id
                                                                    }
                                                                    key={index}
                                                                >
                                                                    <p className="pb-0 mb-0">
                                                                        {
                                                                            letters[
                                                                                index
                                                                            ]
                                                                        }{" "}
                                                                        {
                                                                            answer.answer
                                                                        }
                                                                    </p>

                                                                    <input
                                                                        className="form-check-input float-none m-0"
                                                                        type="checkbox"
                                                                        name="flexRadioDefault"
                                                                        id={
                                                                            answer.answer_id
                                                                        }
                                                                        checked={ getCheckBoxValue(selectedRadio, answer,correctAnswer)}
                                                                        onChange={
                                                                            handleRadioChange
                                                                        }
                                                                        disabled={
                                                                            selectedAnswer
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </label>
                                                            )
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-3 px-xl-5">
                                            <Link to="/data-privacy/case-study" className='d-none'>
                                                Back
                                            </Link>
                                            <button
                                                className="d-flex justify-content-between align-items-center gap-3 w-auto m-auto"
                                                onClick={handleContinue}
                                            >
                                                {buttonName}

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={14}
                                                    viewBox="0 0 22 14"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1 6.99854H21L16 1"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 6.99805L16 12.9995"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="progressbar_custom">
                                            <h6>
                                                {progress} of{" "}
                                                {caseStudyList?.length}
                                            </h6>
                                            <ProgressBar
                                                now={
                                                    (progress /
                                                        caseStudyList.length) *
                                                    100
                                                }
                                            />
                                        </div>
                                        <h2>Optional feedback</h2>
                                    </div>
                                    <div className="optional_feedback d-flex flex-wrap justify-content-center gap-3 pt-3 pt-md-4 pb-5">
                                        <button
                                            className={
                                                activeButton ===
                                                "This was too easy"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleButtonClick(
                                                    "This was too easy"
                                                )
                                            }
                                        >
                                            <span>This was too easy</span>
                                        </button>
                                        <button
                                            className={
                                                activeButton ===
                                                "This was a good case study"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleButtonClick(
                                                    "This was a good case study"
                                                )
                                            }
                                        >
                                            <span>
                                                This was a good case study
                                            </span>
                                        </button>
                                        <button
                                            className={
                                                activeButton ===
                                                "This was too hard"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleButtonClick(
                                                    "This was too hard"
                                                )
                                            }
                                        >
                                            <span>This was too hard</span>
                                        </button>
                                        <button
                                            className={
                                                activeButton === "Other"
                                                    ? "active"
                                                    : ""
                                            }
                                            onClick={() =>
                                                handleButtonClick("Other")
                                            }
                                        >
                                            <span>Other</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>3</h5>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>+3 Points for Completing a Case Study!</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
};

export default BusinessCaseStudyQuestion;
