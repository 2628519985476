import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Rating } from "react-simple-star-rating";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";
import api from "../../helper/api";
import { Modal } from "react-bootstrap";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { CourseContext } from "../../context/CourseContext";

function ShareYourFeedback() {
    const serverURL = getServerURL();
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const { getTrailsTotalPoint } = useContext(CourseContext);


    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!feedback.trim()) {
                toast.error("Please add the feedback!");
                return;
            }
            if (rating === 0) {
                toast.error("Please add rating!");
                return;
            }
            const response = await api.postWithToken(
                `${serverURL}trails/feedback/`,
                {
                    feedback: feedback,
                    rating: rating,
                }
            );
            if (response.data.success) {
                setShow(true);
                await api.postWithToken(`${serverURL}trails/step-progress/`, {
                    trail_step: 10,
                    status: "completed",
                    points_earned: 5,
                    score: 100,
                });

                await api.postWithToken(`${serverURL}trails/point-history/`, {
                    activity_type: "suggest-future-training-topics-completed",
                    activity: "Suggest Future Training Topics Completed",
                    point_gained: 5,
                    point_lost: 0,
                });
                setTimeout(() => {
                    setShow(false);
                    navigate("/data-privacy/trail-results", {
                        state: {
                            step: true,
                        },
                    });
                }, 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const handleSkip = async (event) => {
        event.preventDefault();
        try {
            await api.postWithToken(`${serverURL}trails/step-progress/`, {
                trail_step: 10,
                status: "completed",
                points_earned: 0,
                score: 0,
            });

            await api.postWithToken(`${serverURL}trails/point-history/`, {
                activity_type: "suggest-future-training-topics-completed",
                activity: "Suggest Future Training Topics Completed",
                point_gained: 0,
                point_lost: 0,
            });
            navigate("/data-privacy/trail-results", {
                state: {
                    step: true,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getTrailsTotalPoint()
        if (!location?.state?.step) {
            navigate("/data-privacy-trail");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <PointsHeader to="/data-privacy/points" />
            <section className="py-5 training_topics">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card border-0 question_card_box">
                                <div className="card-body">
                                    <h3>Feedback</h3>
                                    <img
                                        className="feedback_vector"
                                        src="/new-business/feedback0.webp"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-xl-5 question_card_details mt-4 mt-md-5 mt-xl-0">
                            <h3>
                                Tell us how this session helped you or where we
                                can improve.{" "}
                                <span className="optional">(Optional)</span>
                            </h3>
                            <div className="your_rating_box">
                                <h4>Your rating</h4>
                                <div className="star-rating">
                                    <Rating
                                        ratingValue={rating}
                                        size={30}
                                        transition
                                        fillColor="orange"
                                        emptyColor="gray"
                                        onClick={handleRating}
                                    />
                                </div>
                            </div>
                            <textarea
                                rows="10"
                                className="form-control pt-2 mt-3 ai-textarea"
                                placeholder="Type your feedback here..."
                                id="floatingTextarea2"
                                onChange={(e) => setFeedback(e.target.value)}
                                value={feedback}
                            />

                            <div className="d-flex justify-content-end align-items-end pt-4 pb-md-5">
                                <button
                                    onClick={handleSkip}
                                    className="skip_btn"
                                >
                                    Skip
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                                >
                                    Send Feedback
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                        className="ms-3"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99805L16 12.9995"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>5</h5>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>
                            +5 Points for Completing a Apply Knowledge to real
                            life!
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
}

export default ShareYourFeedback;
