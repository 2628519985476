import React, { useEffect, useState } from 'react'
import { Form, Table } from 'react-bootstrap'
import AdminLayout from '../../components/Admin/AdminLayout'
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import CommonLoader from '../../components/Admin/CommonLoader';

function AdminFeedback() {
    const pageSize = 10;
    const serverURL = getServerURL();
    const [feedBack, setFeedback] = useState([])
    const [trailsFeedBack, setTrailsFeedback] = useState([])
    const [page, setPage] = useState(1)
    const [totalFeedback, setTotalFeedback] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [showLoader, setShowLoader] = useState(true);
    const [selectedFeedback, setSelectedFeedback] = useState("courseFeedback")

    const getFeedbackList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
            }).toString();
            const url = selectedFeedback === "courseFeedback" ? "admin-panel/feedback/courses-feedback-list" : "admin-panel/feedback/trail-feedback-list";
            const response = await api.getWithToken(`${serverURL}${url}?${queryParams}`);
            console.log("response",response)
            if(selectedFeedback === "trailsFeedback") {
                setTrailsFeedback(response.data.results.feedback)
            } else {
                setFeedback(response.data.results.feedback);
            }
            setTotalFeedback(response.data.total)
            setTotalPages(response.data.total_pages)
            setShowLoader(false)
        } catch (error) {
            setFeedback([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getFeedbackList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, selectedFeedback ])

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const handleSelect = (e) => {
        setSelectedFeedback(e.target.value);
        setPage(1);
    }



    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <div className="row justify-content-between">
                        <div className="col-4">
                            <h2 className="page-title">Feedback</h2>
                        </div>
                        <div className="col-md-7 col-lg-6 col-xl-4 d-flex justify-content-end feedback_select">
                            <Form.Select aria-label="Default select example" onChange={(e)=> handleSelect(e)}>
                                <option value="courseFeedback">Course Feedback</option>
                                <option value="trailsFeedback">Trails Feedback</option>
                            </Form.Select>
                        </div>
                    </div>
                    {selectedFeedback === "courseFeedback" ? <div className='user-management-table mt-4 overflow-auto'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <p className='text-capitalize '>No</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Course</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Feedback in Stars</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Feedback Text</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Date</p>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>Time</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>User</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {feedBack.length > 0 && feedBack.map((f, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <p className='text-light-400 fs-7 fw-500 mb-0'>{(page - 1) * pageSize + i + 1}</p>
                                        </td>
                                        <td className='px-4'>{f.course_name}</td>
                                        <td className='px-4'>{f.rating}</td>
                                        <td className='px-4'>{f.feedback}</td>
                                        <td className='px-4 text-nowrap'>{f.date}</td>
                                        <td className='px-4 text-nowrap'>{f.time}</td>
                                        <td className='px-4'>{f.user_name}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div> :
                        <div className='user-management-table mt-4 overflow-auto'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <p className='text-capitalize '>No</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Feedback</p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Feedback Id </p>
                                    </th>
                                    <th>
                                        <p className='text-capitalize text-nowrap'>Date</p>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>Time</p>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            <p className='text-capitalize'>User</p>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {trailsFeedBack.length > 0 && trailsFeedBack.map((f, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <p className='text-light-400 fs-7 fw-500 mb-0'>{(page - 1) * pageSize + i + 1}</p>
                                        </td>
                                        <td className='px-4'>{f.feedback}</td>
                                        <td className='px-4'>{f.feedback_id}</td>
                                        <td className='px-4 text-nowrap'>{f.date}</td>
                                        <td className='px-4 text-nowrap'>{f.time}</td>
                                        <td className='px-4'>{f.user_name}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        </div>
                    }
                    <div className='admin-table-pagination'>
                        <p>Showing {(page - 1) * pageSize + 1}-{Math.min(page * pageSize, totalFeedback)} of {totalFeedback}</p>
                        <div className='admin-table-pagination-arrows'>
                            <div className='pagination-arrows-box' onClick={handlePrevPage}>
                                <img src="./admin/pagination-left-arrow.png" alt="" />
                            </div>
                            <div className='pagination-arrows-box'>
                                <img src="./admin/pagination-right-arrow.png" alt="" onClick={handleNextPage} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AdminLayout>
    )
}

export default AdminFeedback
