import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { CourseContext } from "../../context/CourseContext";

function WhatDidYouLearn() {
    const serverURL = getServerURL();
    const location = useLocation();
    const [answer, setAnswer] = useState("");
    const navigate = useNavigate();
    const { getTrailsTotalPoint } = useContext(CourseContext);


    const onSubmitAnswer = async () => {
        try {
            if (!answer.trim()) {
                toast.error("Please answer the answer!");
                return;
            }
            const response = await api.postWithToken(
                `${serverURL}trails/trails-chat-gpt-answer/`,
                {
                    user_answer: answer,
                }
            );
            if (response.data.success) {
                let pass = false;
                if (response.data.data.grade !== "Fail") {
                    pass = true;
                }
                await api.postWithToken(`${serverURL}trails/step-progress/`, {
                    trail_step: 6,
                    status: "completed",
                    points_earned: pass ? 10 : 0,
                    score: pass ? 100 : 0,
                });
                if (pass) {
                    await api.postWithToken(
                        `${serverURL}trails/point-history/`,
                        {
                            activity_type: "what-did-you-learned-completed",
                            activity: "What Did You Learned Completed",
                            point_gained: 10,
                            point_lost: 0,
                        }
                    );
                }
                navigate("/data-privacy/what-did-you-learn/certificate", {
                    state: {
                        grade: response.data.data.grade,
                        pdf: response.data.pdf_data || "",
                    },
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!location?.state?.step) {
            navigate("/data-privacy-trail");
        }
        getTrailsTotalPoint()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <PointsHeader to="/data-privacy/points" />
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="card border-0 question_card_box">
                                <div className="card-body">
                                    <h3>What did you learn?</h3>
                                    <img
                                        src="/new-business/question_card_box.webp"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 ps-xl-5 question_card_details mt-4 mt-md-5 mt-lg-0">
                            <h3>
                                What did you learn? What is the most important
                                thing about data privacy?
                            </h3>
                            <p>
                                Please write a few sentences. It will be graded.
                                AI will generate the grading. If you have
                                questions, please let us know. No personal data
                                is used. Plagiarism and help is not allowed. It
                                is checked and leads to disqualification.
                            </p>

                            <textarea
                                rows="10"
                                className="form-control pt-2 mt-3 ai-textarea"
                                placeholder="Please provide your answer here."
                                id="floatingTextarea2"
                                value={answer}
                                onChange={(e) => {
                                    setAnswer(e.target.value);
                                }}
                            />

                            <div className="d-flex justify-content-end pt-4 pb-md-5">
                                <button
                                    className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                                    onClick={onSubmitAnswer}
                                >
                                    Proceed
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                        className="ms-5"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99805L16 12.9995"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default WhatDidYouLearn;
