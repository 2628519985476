import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { CourseContext } from "../../context/CourseContext";
function Certificate() {
    const location = useLocation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [result, setResult] = useState({
        grade: "",
        pdf: "",
    });
    const { getTrailsTotalPoint } = useContext(CourseContext);

    const handleClose = () => setShow(false);

    useEffect(() => {
        if (location?.state?.grade) {
            getTrailsTotalPoint()
            setResult(location.state);
            if (location?.state?.grade !== "Fail") {
                setShow(true);
            }
        } else {
            navigate("/data-privacy-trail");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadPdf = () => {
        console.log("result", result);
        // Convert the Base64 string to a Blob
        const byteCharacters = atob(result.pdf); // Decode the base64 string
        const byteArrays = [];

        // Convert each character to a byte
        for (let offset = 0; offset < byteCharacters.length; offset++) {
            const byte = byteCharacters.charCodeAt(offset);
            byteArrays.push(byte);
        }

        // Create a Blob from the byte array
        const blob = new Blob([new Uint8Array(byteArrays)], {
            type: "application/pdf",
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        link.download = "certificate.pdf"; // Set the download filename
        link.click(); // Trigger the download
    };

    return (
        <>
            <Header />
            <PointsHeader to="/data-privacy/points" />
            <section className="py-5">
                <div className="container">
                    <div
                        className={`row certificate_card ${
                            result.grade === "Fail" && "justify-content-center"
                        }`}
                    >
                        <div className="col-xl-6">
                            <div className="card border-0 question_card_box px-0">
                                <img
                                    src="/featured-courses/girl-party.jpg"
                                    alt=""
                                />
                                <div className="card-body">
                                    <h6>You passed Score</h6>
                                    <h4>{result.grade}</h4>
                                </div>
                            </div>
                            {result.grade === "Fail" && (
                                <div>
                                    <div className="d-flex justify-content-end pt-4 pb-md-5">
                                        <Link
                                            to="/data-privacy/whats-open"
                                            state={{
                                                step: true,
                                            }}
                                            className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                                        >
                                            Proceed
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                                className="ms-5"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                        {result.grade !== "Fail" && (
                            <div className="col-xl-6 ps-xl-5 certificate_card_details mt-4 mt-md-5 mt-xl-0">
                                <div className="card">
                                    <div className="card-body">
                                        <span>Certificate</span>
                                        <h2>You Earned The Certificate!</h2>
                                        <p>
                                            You’ve successfully completed the
                                            trail and earned a certificate to
                                            showcase your hard work and
                                            dedication.
                                        </p>
                                        <p>
                                            You can download your certificate
                                            now and share it.
                                        </p>
                                        <button onClick={downloadPdf}>
                                            Download Certificate{" "}
                                            <img
                                                src="/new-business/download.webp"
                                                className="pb-0 mix-blend-mode-inherit"
                                                width={30}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end pt-4 pb-md-5">
                                    <Link
                                        to="/data-privacy/whats-open"
                                        state={{
                                            step: true,
                                        }}
                                        className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                                    >
                                        Proceed
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={22}
                                            height={14}
                                            viewBox="0 0 22 14"
                                            fill="none"
                                            className="ms-5"
                                        >
                                            <path
                                                d="M1 6.99854H21L16 1"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M21 6.99805L16 12.9995"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>10</h5>
                        </div>

                        <h3>Congratulations!</h3>
                        <p>+10 Points for Completing a What did you learned!</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
}

export default Certificate;
