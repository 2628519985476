import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { useNavigate } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const WhatsOpenAi = () => {
    const navigate = useNavigate();
    const { pointsData, getTrailsTotalPoint } = useContext(CourseContext);
    const serverURL = getServerURL();
    const [question, setQuestion] = useState("");
    const [answers, setAnswers] = useState("");
    const [buttonName, setButtonName] = useState("Skip");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const getAnswer = async () => {
        try {
            const response = await api.postWithToken(
                `${serverURL}trails/ask-openai/`,
                {
                    question: question,
                }
            );
            if (response.data.success) {
                setButtonName("Next");
                setAnswers(response.data.data.answer);
            } else {
                toast.error("Please try again later.");
            }
        } catch (error) {
            // console.error(error);
            toast.error("Please try again later.");
        }
    };

    const onClearData = () => {
        setQuestion("");
        setAnswers("");
    };

    useEffect(() => {
        getTrailsTotalPoint();
        if (!pointsData?.total_point_earned) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSkip = async (e) => {
        e.preventDefault();
        try {
            if (buttonName === "Skip") {
                await api.postWithToken(`${serverURL}trails/step-progress/`, {
                    trail_step: 7,
                    status: "completed",
                    points_earned: 0,
                    score: 0,
                });
                await api.postWithToken(`${serverURL}trails/point-history/`, {
                    activity_type: "whats-open-completed",
                    activity: "Whats Open Completed",
                    point_gained: 0,
                    point_lost: 0,
                });
                navigate("/data-privacy/apply-knowledge-to-real-life", {
                    state: {
                        step: true,
                    },
                });
            } else {
                setShow(true);

                await api.postWithToken(`${serverURL}trails/step-progress/`, {
                    trail_step: 7,
                    status: "completed",
                    points_earned: 5,
                    score: 100,
                });
                await api.postWithToken(`${serverURL}trails/point-history/`, {
                    activity_type: "whats-open-completed",
                    activity: "Whats Open Completed",
                    point_gained: 5,
                    point_lost: 0,
                });
                setTimeout(() => {
                    setShow(false);
                    navigate("/data-privacy/apply-knowledge-to-real-life", {
                        state: {
                            step: true,
                        },
                    });
                }, 3000);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Header />
            <main className="business_unanswered_questions">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                </section>
                <section className="questions_boxs">
                    <div className="container">
                        <h3>
                            What question is still open for you?{" "}
                            <span className="optional">(Optional)</span>
                        </h3>
                        <p>
                            AI will answer. If you have questions, please let us
                            know. No personal data is used.
                        </p>

                        <div className="row justify-content-between align-items-start">
                            <div className="col-12 col-md-6 pe-lg-5">
                                <label htmlFor="">Your Question</label>
                                <textarea
                                    className="form-control pt-3 bg-secondary fw-400"
                                    rows={6}
                                    placeholder="Type your question here..."
                                    id="floatingTextarea2"
                                    value={question}
                                    onChange={handleQuestionChange}
                                />
                                <div className="d-flex justify-content-end gap-3 mt-4 questions_boxs_btn_1">
                                    <button
                                        className="btn bg-secondary d-none"
                                        onClick={() => navigate(-1)}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="btn bg-primary text-white"
                                        onClick={getAnswer}
                                    >
                                        Ask AI
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pe-lg-5 questions_boxs_btn_2 mt-5 mt-md-0">
                                <label htmlFor="">Answer</label>
                                <textarea
                                    className="form-control pt-3 bg-transparent fw-400"
                                    rows={6}
                                    placeholder="Answer will appear here..."
                                    id="floatingTextarea2"
                                    value={answers || ""}
                                />
                                {answers && (
                                    <div className="d-flex justify-content-between align-items-center gap-3 mt-4">
                                        <label htmlFor="" className="mb-0">
                                            Was this helpful?
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center gap-3">
                                            <button
                                                className="btn bg-primary text-white"
                                                onClick={onClearData}
                                            >
                                                Yes
                                            </button>
                                            <button
                                                className="btn bg-secondary"
                                                onClick={onClearData}
                                            >
                                                No
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end pt-4 pb-md-5">
                            <button
                                onClick={handleSkip}
                                className="btn-explore p-sm-15 d-flex justify-content-between align-items-center gap-3 rounded-2 mt-md-4"
                            >
                                {buttonName}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={22}
                                    height={14}
                                    viewBox="0 0 22 14"
                                    fill="none"
                                    className="ms-5"
                                >
                                    <path
                                        d="M1 6.99854H21L16 1"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 6.99805L16 12.9995"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </section>
            </main>
            <Modal
                className="congratulations_modal_custom"
                show={show}
                centered
                size="lg"
                onHide={handleClose}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>5</h5>
                        </div>
                        <h3>Congratulations!</h3>
                        <p>+5 Points for Completing a Whats Open!</p>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
};

export default WhatsOpenAi;
