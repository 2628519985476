import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";
import { Link, useNavigate } from "react-router-dom";
import { decryptFromStoreLoc } from "../../helper/localStore";
import api from "../../helper/api";
import { getImageBaseUrl, getServerURL } from "../../helper/envConfig";

const BusinessCaseStudiesResults = () => {
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const imageURL = getImageBaseUrl();

    const [caseStudies, setCaseStudies] = useState([]);
    const [total, setTotal] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [points, setPoints] = useState(0);

    // const caseStudies = [
    //     {
    //         title: "Course",
    //         description:
    //             "Finish up with 3 engaging courses in data privacy to round out your training.",
    //         imageSrc: "/new-business/course.webp",
    //     },
    //     {
    //         title: "Puzzle",
    //         description:
    //             "Complete the puzzle to reinforce your understanding of privacy fundamentals.",
    //         imageSrc: "/new-business/puzzle.webp",
    //     },
    //     {
    //         title: "Gamified Quiz",
    //         description:
    //             "Ready for a challenge? Test your knowledge in a quiz game! Answer 7 out of 10 questions correctly to progress and earn points.",
    //         imageSrc: "/new-business/gamified.webp",
    //     },
    // ];

    const getSteps = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/steps/`
            );
            setCaseStudies(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSteps();
    }, []);

    useEffect(() => {
        const data = decryptFromStoreLoc("caseStudyResult");
        if (data) {
            setTotal(data.total);
            setCorrectAnswer(data.correct);
            setPercentage(data.score);
            setPoints(data.points_earned);
        }
    }, []);

    const handleReplay = () => {
        navigate("/data-privacy/case-study");
    };

    return (
        <>
            <Header />
            <main className="business_public_spaces business_results">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                </section>
                <section className="business_case_study">
                    <div className="container-fluid">
                        <div className="row w-100 w-sm-100">
                            <div className="col-12 col-md-6 left_side_images">
                                <img
                                    src="/new-business/congratulations.webp"
                                    className="m-auto d-flex"
                                    width={500}
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 d-flex justify-content-start align-items-center">
                                <div className="business_results_right text-center">
                                    <h4>{percentage}%</h4>
                                    {percentage > 0 && <h5>Congratulations!</h5>}
                                    <h6>
                                        You got {correctAnswer} out of {total}{" "}
                                        questions
                                    </h6>
                                    <p>
                                        You have successfully completed the case
                                        study, now you can proceed to the quiz.
                                    </p>
                                    <div className="d-flex justify-content-center align-items-center gap-2">
                                        <h3 className="mb-0 points_earned">
                                            Points earned:
                                        </h3>
                                        <div className="business_data_privacy_trail">
                                            <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                <img
                                                    src="/new-business/award-icons.webp"
                                                    width={24}
                                                    alt=""
                                                />
                                                {points}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center gap-3 right_side_information px-0">
                                        <button onClick={handleReplay}>
                                            Replay
                                        </button>
                                        <Link
                                            to="/data-privacy/course"
                                            state= {{
                                                step: true
                                            }}
                                            className="d-flex justify-content-between align-items-center"
                                        >
                                            Start Courses
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={14}
                                                viewBox="0 0 22 14"
                                                fill="none"
                                            >
                                                <path
                                                    d="M1 6.99854H21L16 1"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M21 6.99805L16 12.9995"
                                                    stroke="white"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-4 still_questions">
                                        <span>
                                            Still have questions?{" "}
                                            <Link to="/data-privacy/ask-to-ai">
                                                Click here.
                                            </Link>
                                        </span>
                                    </div>

                                    <div>
                                        <div className="next_steps_title">
                                            <p className="text-start mt-4 mb-3 mt-lg-5 mb-lg-4">
                                                Next Steps:
                                            </p>
                                        </div>
                                        <div className="row g-4 course_cards pb-5">
                                            {caseStudies
                                                ?.slice(1)
                                                ?.map((caseStudy, index) => (
                                                    <div
                                                        className="col-12"
                                                        key={index}
                                                    >
                                                        <div className="card border-0 rounded-3">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-start align-content-md-center gap-4 flex-column flex-md-row text-start">
                                                                    <img
                                                                        src={
                                                                            caseStudy.icon
                                                                        }
                                                                        alt={""}
                                                                    />
                                                                    <div>
                                                                        <h6>
                                                                            {
                                                                                caseStudy.name
                                                                            }
                                                                        </h6>
                                                                        <p className="mb-2">
                                                                            {
                                                                                caseStudy.description
                                                                            }
                                                                        </p>
                                                                        <div className="d-flex align-items-center gap-3">
                                                                            <p className="mb-0 text_light_200 fs-14 fw-600">
                                                                                You
                                                                                will
                                                                                earn:
                                                                            </p>
                                                                            <div className="business_data_privacy_trail">
                                                                                {caseStudy.title !==
                                                                                    "Puzzle" && (
                                                                                    <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                                                        <img
                                                                                            src="/new-business/award-icons.webp"
                                                                                            width={
                                                                                                24
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                        {/* {caseStudy.awardCount} */}
                                                                                        {
                                                                                            caseStudy?.points
                                                                                        }
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default BusinessCaseStudiesResults;
