import React, { useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import "../../styles/newbusiness.css";
import PointsHeader from "../../components/NewBusiness/PointsHeader";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import api from "../../helper/api";
import { getServerURL } from "../../helper/envConfig";
import { toast } from "react-toastify";
import { CourseContext } from "../../context/CourseContext";
import ReportAnIssue from "../../components/NewBusiness/ReportAnIssue";


const BusinessPuzzle = () => {
    const { getTrailsTotalPoint, pointsData, user } = useContext(CourseContext);
    const serverURL = getServerURL();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [items, setItems] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [userAnswers, setUserAnswers] = useState([]);
    const [progressData, setProgressData] = useState({});
    const [showResult, setShowResult] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [scoreData, setScoreData] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [caseStudies, setCaseStudies] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [congratulationsModel, setCongratulationsModel] = useState(false);
    const [mobileViewAnswers, setMobileViewAnswers] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
    const [selectedMobileAnswers, setSelectedMobileAnswers] = useState([]);
    const [selectedAnswerId, setSelectedAnswerId] = useState(null);
    const [draggedItem, setDraggedItem] = useState(null);
    const [answerIds, setAnswerIds] = useState([]);

    const handleShow = (index) => {
        setShow(true);
        setCurrentQuestionIndex(index);
    };

    const getQuestions = async () => {
        try {
            const type =
                user.main_role === 1 || user.role === 2 ? "business" : "kid";
            const response = await api.getWithToken(
                `${serverURL}trails/puzzle?type=${type}`
            );
            setQuestions(response.data.questions);
            setMobileViewAnswers(response.data.questions);
            setItems(response?.data?.answers);
            setAnswerIds(response.data.answers.map((item) => item.answer_id));
        } catch (error) {
            console.log(error);
        }
    };

    const getSteps = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/steps/`
            );
            setCaseStudies(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSteps();
        getQuestions();
    }, []);

    const handleCardClick = (index, answer_id) => {
        setActiveIndex(index === activeIndex ? null : index); // Toggle active state
        setSelectedAnswerId(answer_id); // Set the active answer id
    };

    const handleSubmitResult = async () => {
        try {
            if (userAnswers.length !== 5) {
                toast.error("Please move all answers!");
                return;
            }

            const result = await api.postWithToken(
                `${serverURL}trails/check-puzzle-answers/`,
                userAnswers
            );
            setResultData(result.data.data);
            handleColorChange(result.data.data);
            setShowResult(true);
            window.scrollTo(0, 0);
            const total = questions.length;
            const correctAnswers = result.data.data.filter(
                (item) => item.is_correct
            ).length;
            const percentage = (correctAnswers / total) * 100;
            setScoreData({
                percentage: percentage,
                correctAnswers: correctAnswers,
                total: total,
            });
            await api.postWithToken(`${serverURL}trails/step-progress/`, {
                trail_step: 3,
                status: "completed",
                points_earned: correctAnswers,
                score: Math.round((correctAnswers / total) * 100),
            });
            if (correctAnswers !== 0) {
                setCongratulationsModel(true);
                await api.postWithToken(`${serverURL}trails/point-history/`, {
                    activity_type: "puzzle-completed",
                    activity: "Puzzle Completed",
                    point_gained: correctAnswers,
                    point_lost: 0,
                });
                setTimeout(() => {
                    setCongratulationsModel(false);
                }, 2000);
            }

            getTrailsTotalPoint();
            setIsSubmit(true);
        } catch (error) {
            // console.log("error", error);
        }
    };

    const getProgressStatus = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}trails/step-status/`
            );
            setProgressData(response.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProgressStatus();
        if (!pointsData?.total_point_earned) {
            getTrailsTotalPoint();
        }
    }, []);

    const handleMobileSubmit = () => {
        const answer = items.find((ans) => ans.answer_id == selectedAnswerId);
        const newQuestion = [...mobileViewAnswers];
        const ans = [...selectedMobileAnswers];
        if (newQuestion[currentQuestionIndex].answer) {
            let index = ans.findIndex(
                (id) => id == newQuestion[currentQuestionIndex].answer_id
            );
            if (index !== -1) {
                ans.splice(index, 1);
            }
        }
        newQuestion[currentQuestionIndex].answer = answer.answer;
        newQuestion[currentQuestionIndex].answer_id = answer.answer_id;
        setMobileViewAnswers(newQuestion);
        ans.push(answer.answer_id);
        setSelectedMobileAnswers(ans);

        const newAns = newQuestion.map((que) => {
            return {
                question_id: que.question_id,
                answer_id: que.answer_id,
            };
        });
        setUserAnswers(newAns);

        handleClose();
    };

    const handleNavigate = () => {
        navigate("/data-privacy/gamified-quiz", {
            state: {
                step: true
            }
        });
    };

    const replay = () => {
        setItems([]);
        setActiveIndex(null);
        setUserAnswers([]);
        setResultData([]);
        setShowResult(false);
        setIsSubmit(false);
        getSteps();
        getQuestions();
        handleReplay();
    };

    const MobileDroppableDiv = ({
        index,
        currentValue,
        questionId,
        answer_id,
    }) => {
        const obj = resultData?.find((item) => item.question_id === questionId);
        let bgColor = "";
        let textColor = "";

        if (showResult) {
            if (obj?.is_correct) {
                bgColor = "#ECFAEF";
                textColor = "#27B246";
            } else {
                bgColor = "#FCE8EE";
                textColor = "#DB0544";
            }
        } else {
            bgColor = currentValue ? "#EDF2FF" : "#fff";
            textColor = currentValue ? "#00237D" : "#333";
        }

        return (
            <>
                {currentValue ? (
                    <div
                        style={{
                            padding: "20px",
                            backgroundColor: bgColor,
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                    >
                        <p style={{ color: textColor }}>{currentValue}</p>
                    </div>
                ) : (
                    "Drop Here"
                )}
            </>
            // </div>
        );
    };

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            // Check if the width is less than or equal to 768px (typical mobile breakpoint)
            if (window.innerWidth <= 768) {
                // Reload the page when the dimension is mobile
                window.location.reload();
            }
        };

        // Add the resize event listener when the component mounts
        window.addEventListener("resize", handleResize);

        // Cleanup event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const allowDrop = (event) => {
        event.preventDefault();
    };

    const drag = (event) => {
        setDraggedItem(event.target.id); // Set the dragged item when drag starts
    };

    const drop = (event, index) => {
        event.preventDefault();

        // Prevent drop if the drop zone already has a card
        if (items[index]?.answer !== null) {
            // return; // If the drop zone already has a card, do nothing
        }
        const dr = document.getElementById(`answer-${index}`);
        const drgElement = document.getElementById(draggedItem);
        for (const a of answerIds) {
            const id = `#dragData-${a}`;
            const found = dr.querySelector(id);
            if (found) {
                const ansList = document.getElementById(`answers`);
                ansList.appendChild(found);
                found.style.backgroundColor = "";
                found.className = "dragData";
            }
        }
        drgElement.style.backgroundColor = "#EDF2FF";
        // drgElement.className = "drop-card";
        drgElement.classList.add("drop-card");
        dr.appendChild(drgElement);

        const questionId = questions[index].question_id;
        const updatedUserAnswers = [...userAnswers];

        // remove anser if already exists
        const ansId = draggedItem.split("-")[1];
        const ansObjIndex = userAnswers.findIndex(
            (ans) => ans.answer_id === Number(ansId)
        );
        if (ansObjIndex !== -1) {
            updatedUserAnswers.splice(ansObjIndex, 1);
        }

        const obj = userAnswers.findIndex(
            (ans) => ans.question_id === questionId
        );

        if (obj !== -1) {
            updatedUserAnswers[obj].answer_id = Number(ansId);
        } else {
            updatedUserAnswers.push({
                question_id: questionId,
                answer_id: Number(ansId),
            });
        }
        setUserAnswers(updatedUserAnswers); // Save the updated answers
        handleHideDropText();
        handleAddDropHereDiv();
    };

    const handleColorChange = (result) => {
        const ansListDiv = document.getElementById("ansList");
        for (const child of ansListDiv.children) {
            const ansDiv = child.querySelector(".dragData");
            const id = ansDiv.id.split("-")[1];
            const isTrue = result.find((item) => item.answer_id === Number(id));
            ansDiv.className = "dragData";
            let bgColor = "";
            let textColor = "";
            if (isTrue?.is_correct) {
                bgColor = "#ECFAEF";
                textColor = "#27B246";
            } else {
                bgColor = "#FCE8EE";
                textColor = "#DB0544";
            }
            ansDiv.style.backgroundColor = bgColor;
            ansDiv.style.color = textColor;
            ansDiv.removeAttribute("draggable");
        }
    };
    const handleHideDropText = () => {
        const ansListDiv = document.getElementById("ansList");
        for (const child of ansListDiv.children) {
            const cld = child.children;
            if (cld.length === 2) {
                cld[0].remove();
            }
        }
    };

    const handleReplay = () => {
        const arr = [0, 1, 2, 3, 4];
        for (const index of arr) {
            const dr = document.getElementById(`answer-${index}`);
            for (const a of answerIds) {
                const id = `#dragData-${a}`;
                const found = dr.querySelector(id);
                if (found) {
                    // Create a new <p> element
                    const newP = document.createElement("p");
                    newP.className = "mb-0"; // Adding class 'mb-0'
                    newP.textContent = "Drop here"; // Setting the answer text to item.answer
                    // Append the <p> to the new <div>
                    dr.appendChild(newP);
                    // Replace the found element with the new <div>
                    found.remove();
                }
            }
        }
    };

    const handleAddDropHereDiv = () => {
        const arr = [0, 1, 2, 3, 4];
        for (const index of arr) {
            const dr = document.getElementById(`answer-${index}`);
            const cld = dr.children;
            if (cld.length === 0) {
                const newP = document.createElement("p");
                newP.className = "mb-0"; // Adding class 'mb-0'
                newP.textContent = "Drop here"; // Setting the answer text to item.answer
                dr.appendChild(newP);
            }
        }
    };

    return (
        <>
            <Header />
            <main className="business_puzzle">
                <section>
                    <PointsHeader to="/data-privacy/points" />
                </section>
                <section>
                    <div className="container">
                        {!isSubmit && (
                            <div className="puzzle_top_box">
                                <div className="row">
                                    <div className="col-12 col-md-8">
                                        <span>Puzzle</span>
                                        <h2>
                                            Puzzle: 5 Key Things for Data
                                            Privacy
                                        </h2>
                                        <p>
                                            Match each data privacy principle
                                            with its corresponding description.
                                            Drag and drop the descriptions to
                                            the correct principles.
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4 d-none d-md-flex justify-content-center">
                                        <img
                                            src="/new-business/puzzle-game.webp"
                                            width={226}
                                            height={226}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {isSubmit && (
                            <div className="puzzle_top_box">
                                <div className="row pb-0 pb-md-5 align-items-center">
                                    <div className="col-12 col-md-8">
                                        <span>Results</span>
                                        <div className="d-flex align-items-start align-items-md-center gap-0 gap-md-3 pb-3 pb-md-4">
                                            <h2 className="results_text">
                                                {scoreData?.percentage}%
                                            </h2>
                                            <div className="d-flex justify-content-center align-items-center gap-2">
                                                <h3 className="mb-0 points_earned text-white fs-20">
                                                    Points earned:
                                                </h3>
                                                <div className="business_data_privacy_trail">
                                                    <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                        <img
                                                            src="/new-business/award-icons.webp"
                                                            width={24}
                                                            alt=""
                                                        />
                                                        {
                                                            scoreData?.correctAnswers
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            Match each data privacy principle
                                            with its corresponding description.
                                            Drag and drop the descriptions to
                                            the correct principles.
                                        </p>
                                        <div className="results_btn py-3 d-md-flex align-items-center gap-4">
                                            <button onClick={replay}>
                                                Replay
                                            </button>
                                            <button
                                                className="d-flex justify-content-center align-items-center gap-3 mt-3 mt-md-0"
                                                onClick={handleNavigate}
                                            >
                                                Start Quiz{" "}
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={14}
                                                    viewBox="0 0 22 14"
                                                    fill="none"
                                                >
                                                    <path
                                                        d="M1 6.99854H21L16 1"
                                                        stroke="#000"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M21 6.99805L16 12.9995"
                                                        stroke="#000"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <p className="still_questions">
                                            Still have questions?{" "}
                                            <Link to="/data-privacy/ask-to-ai" state={{step:true}}>
                                                Click here.
                                            </Link>
                                        </p>
                                    </div>
                                    <div className="col-12 col-md-4 d-none d-md-flex justify-content-center">
                                        <img
                                            src="/new-business/puzzle-game.webp"
                                            width={226}
                                            height={226}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* <DndProvider backend={HTML5Backend}> */}
                        <div className="row justify-content-center mt-4 mt-lg-5 pb-4 pb-md-5">
                            <div className="col-12 col-lg-11">
                                <div className="card drag_drop_cards">
                                    <div className="card-body">
                                        <div className="row">
                                            <div
                                                className="col-12 col-md-4 drag_drop_col_1 none"
                                                id="answers"
                                            >
                                                {items?.map((item, index) => (
                                                    <div
                                                        className="mt-2"
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "330px",
                                                            marginBottom:
                                                                "20px",
                                                            backgroundColor:
                                                                "transparent",
                                                        }}
                                                        key={index}
                                                    >
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                height: "180px",
                                                                padding: "10px",
                                                                border: "1px solid #E6E6E6",
                                                                backgroundColor:
                                                                    "#fff",
                                                                borderRadius:
                                                                    "5px",
                                                                boxShadow:
                                                                    "0 2px 5px rgba(0, 0, 0, 0.1)",
                                                                cursor: "move",
                                                                textAlign:
                                                                    "center",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                            key={`dragData-${index}`}
                                                            id={`dragData-${item.answer_id}`}
                                                            className="dragData"
                                                            draggable="true"
                                                            onDragStart={drag}
                                                        >
                                                            <div>
                                                                <p className="mb-0">
                                                                    {
                                                                        item.answer
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-md-4">
                                                {questions?.map(
                                                    (question, index) => (
                                                        <div
                                                            // className="d-flex flex-column flex-md-row gap-3 w-100"
                                                            key={index}
                                                        >
                                                            <div className="card w-100 drag_drop_col_2">
                                                                <div className="card-body">
                                                                    <div className="d-flex justify-content-end mb-2">
                                                                        <ReportAnIssue
                                                                            trailStep={
                                                                                3
                                                                            }
                                                                            questionId={
                                                                                question.question_id
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {" "}
                                                                        {
                                                                            question.question
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            {/* <div className="card w-100 drag_drop_col_3 desktop border-0 p-0">
                                                                <div
                                                                    className="card-body d-flex justify-content-center align-items-center p-0"
                                                                    id="ansList"
                                                                >
                                                                    <div
                                                                        key={`getData-${index}`}
                                                                        id={`answer-${index}`}
                                                                        className="dropZone"
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "200px",
                                                                            padding:
                                                                                "10px",
                                                                            border: "2px dashed #4f4d4d",
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "center",
                                                                            alignItems:
                                                                                "center",
                                                                            position:
                                                                                "relative",
                                                                        }}
                                                                        onDrop={(
                                                                            e
                                                                        ) =>
                                                                            drop(
                                                                                e,
                                                                                index
                                                                            )
                                                                        }
                                                                        onDragOver={
                                                                            allowDrop
                                                                        }
                                                                    >
                                                                        <p
                                                                            className={`${
                                                                                question?.answer
                                                                                    ? "d-none"
                                                                                    : "Drop here"
                                                                            }`}
                                                                        >
                                                                            {question?.answer
                                                                                ? ""
                                                                                : "Drop here"}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            <div
                                                                className="card w-100 drag_drop_col_3 mobile border-0 p-0"
                                                                onClick={() =>
                                                                    handleShow(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <div className="card-body d-flex justify-content-center align-items-center p-0">
                                                                    <div className="click_here">
                                                                        <MobileDroppableDiv
                                                                            index={
                                                                                index
                                                                            }
                                                                            currentValue={
                                                                                mobileViewAnswers[
                                                                                    index
                                                                                ]
                                                                                    ?.answer
                                                                            }
                                                                            questionId={
                                                                                mobileViewAnswers[
                                                                                    index
                                                                                ]
                                                                                    ?.question_id
                                                                            }
                                                                            answer_id={
                                                                                mobileViewAnswers[
                                                                                    index
                                                                                ]
                                                                                    ?.answer_id
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div
                                                className="col-12 col-md-4"
                                                id="ansList"
                                            >
                                                {questions?.map(
                                                    (question, index) => (
                                                        <div
                                                            key={`getData-${index}`}
                                                            id={`answer-${index}`}
                                                            className="dropZone mb-4 card-body p-0"
                                                            style={{
                                                                width: "100%",
                                                                height: "180px",
                                                                border: "1px dashed #4f4d4d",
                                                                display: "flex",
                                                                borderRadius:
                                                                    "10px",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                position:
                                                                    "relative",
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                            onDrop={(e) =>
                                                                drop(e, index)
                                                            }
                                                            onDragOver={
                                                                allowDrop
                                                            }
                                                        >
                                                            <p
                                                                className={`${
                                                                    question?.answer
                                                                        ? "d-none"
                                                                        : "Drop here m-2"
                                                                }`}
                                                            >
                                                                Drop here
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>

                                        {isSubmit && (
                                            <>
                                                <div className="text-start mt-3 mt-lg-5 trail_progressbar pt-md-5">
                                                    <h2 className="mb-2 mb-lg-3">
                                                        Data Privacy Trail
                                                        Progress
                                                    </h2>
                                                    <span className="mb-3 d-inline-block">
                                                        {
                                                            progressData?.total_complete_step
                                                        }
                                                        /
                                                        {
                                                            progressData?.total_step
                                                        }{" "}
                                                        Completed
                                                    </span>
                                                    <ProgressBar
                                                        className="me-md-4 me-lg-5"
                                                        now={
                                                            (progressData?.total_complete_step /
                                                                progressData?.total_step) *
                                                            100
                                                        }
                                                    />
                                                </div>

                                                <div>
                                                    <div className="next_steps_title">
                                                        <p className="text-start my-3 mt-lg-5 mb-lg-4">
                                                            Next Steps:
                                                        </p>
                                                    </div>
                                                    <div className="row g-4 course_cards pb-2">
                                                        {caseStudies
                                                            ?.slice(3)
                                                            .map(
                                                                (
                                                                    caseStudy,
                                                                    index
                                                                ) => (
                                                                    <div
                                                                        className="col-12"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className="d-flex align-items-start align-content-md-center gap-4 flex-column flex-md-row">
                                                                                    <img
                                                                                        src={
                                                                                            caseStudy.icon
                                                                                        }
                                                                                        alt={
                                                                                            "icon"
                                                                                        }
                                                                                    />
                                                                                    <div>
                                                                                        <h4>
                                                                                            {
                                                                                                caseStudy.name
                                                                                            }
                                                                                        </h4>
                                                                                        <p className="mb-2">
                                                                                            {
                                                                                                caseStudy.description
                                                                                            }
                                                                                        </p>
                                                                                        <div className="d-flex align-items-center gap-3">
                                                                                            <p className="mb-0 text_light_200 fs-14 fw-600">
                                                                                                You
                                                                                                will
                                                                                                earn:
                                                                                            </p>
                                                                                            <div className="business_data_privacy_trail">
                                                                                                {caseStudy.title !==
                                                                                                    "Puzzle" && (
                                                                                                    <button className="award_btn fw-700 border-0 d-flex align-items-center gap-2">
                                                                                                        <img
                                                                                                            src="/new-business/award-icons.webp"
                                                                                                            width={
                                                                                                                24
                                                                                                            }
                                                                                                            alt=""
                                                                                                        />
                                                                                                        {/* {caseStudy.awardCount} */}
                                                                                                        {
                                                                                                            caseStudy?.points
                                                                                                        }
                                                                                                    </button>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </DndProvider> */}

                        {!isSubmit && (
                            <div className="check_answers_btn d-flex justify-content-center pt-4 mt-md-5">
                                <button
                                    className="d-flex align-items-center"
                                    onClick={handleSubmitResult}
                                >
                                    Check My Answers
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={14}
                                        viewBox="0 0 22 14"
                                        fill="none"
                                    >
                                        <path
                                            d="M1 6.99854H21L16 1"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M21 6.99805L16 12.9995"
                                            stroke="white"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </div>
                </section>
            </main>
            <Footer />

            <Modal
                show={show}
                size="lg"
                className="feedback_modal"
                onHide={handleClose}
                scrollable
            >
                <Modal.Header className="adjust-modal" closeButton>
                    <Modal.Title>Select Answer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="drag_drop_col_1">
                        {items?.map((item, index) => {
                            if (
                                !selectedMobileAnswers.includes(item.answer_id)
                            ) {
                                return (
                                    <>
                                        <div
                                            key={index}
                                            className={`card mb-3 ${
                                                activeIndex === index
                                                    ? "active"
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleCardClick(
                                                    index,
                                                    item.answer_id
                                                )
                                            }
                                        >
                                            <div className="card-body p-2 cursor-pointer">
                                                <p className="mb-0 fs-6 fw-500 text-bl">
                                                    {index + 1}. {item.answer}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                );
                            }
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-2">
                    <Button variant="primary" onClick={handleMobileSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                className="congratulations_modal_custom"
                show={congratulationsModel}
                centered
                size="lg"
                onHide={() => setCongratulationsModel(false)}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="position-relative correct_answers d-flex justify-content-center m-auto">
                            <img
                                src="/new-business/points.webp"
                                className="m-auto d-flex justify-content-center"
                                alt=""
                            />
                            <h5>{scoreData?.correctAnswers}</h5>
                        </div>

                        <h3>Congratulations!</h3>
                        <p>
                            +{scoreData?.correctAnswers} Points for Completing a
                            Puzzle!
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default BusinessPuzzle;
